import Vue from 'vue';
import App from './App.vue';
import router from './router';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueApexCharts from 'vue-apexcharts';
import locale from 'element-ui/lib/locale/lang/ko';
import vueCookies from "vue-cookies";
import axios from "axios";
import Vue2Editor from "vue2-editor";
import VueI18n from 'vue-i18n';
import ko from './i18n/ko.json';
import cn from './i18n/cn.json';

import('./assets/css/common.css');
import('./assets/css/default.css');
import('./assets/css/header.css');
import('./assets/css/jinmyeong.css');

Vue.use(ElementUI, { locale });
Vue.config.productionTip = false;
Vue.use(VueApexCharts);
Vue.use(vueCookies);
Vue.use(Vue2Editor);
Vue.use(VueI18n);

const mergedKoMessages = {
  ...ko,
};

const mergedCnMessages = {
  ...cn,
};

const i18n = new VueI18n({
  locale: 'ko', // 기본 언어
  messages: {
    'ko': mergedKoMessages,
    'cn': mergedCnMessages,
  }
});

Vue.prototype.$axios = axios.create({
  // baseURL: "https://org.peachbite.co.kr/api",
  headers: {
    'Access-Control-Allow-Credentials': true,
    'ngrok-skip-browser-warning': true,
  },
  // baseURL: "http://localhost:8000/api"
});

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')