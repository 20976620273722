import Vue from "vue";
import VueRouter from "vue-router";
import Navigation100 from "../components/Navigation100.vue";
import Navigation200 from "../components/Navigation200.vue";
import Navigation300 from "../components/Navigation300.vue";
import Navigation500 from "../components/Navigation500.vue";
import Navigation600 from "../components/Navigation600.vue";
import login from "../views/login.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/index",
    component: () => import(/* webpackChunkName: "index_parent" */ "@/layout/default.vue"),
    children: [
      {
        path: "",
        name: "Index",
        component: () => import(/* webpackChunkName: "index" */ "@/views/index.vue"),
      },
    ],
  },
  {
    path: "/",
    name: "Login",
    component: login,
  },
  {
    path: "/login",
    name: "Login",
    component: login,
  },

  {
    path: "/login",
    component: () => import(/* webpackChunkName: "login" */ "@/layout/login.vue"),
    children: [
      {
        path: "id_lost",
        name: "IdLost",
        component: () => import(/* webpackChunkName: "IdLost" */ "@/views/login/id_lost.vue"),
      },
      {
        path: "password_lost",
        name: "PasswordLost",
        component: () => import(/* webpackChunkName: "PasswordLost" */ "@/views/login/password_lost.vue"),
      },
      {
        path: "register",
        name: "Register",
        component: () => import(/* webpackChunkName: "Register" */ "@/views/login/register.vue"),
      },
    ],
  },
  {
    path: "/Navigation100",
    name: "Navigation100",
    component: Navigation100,
  },
  {
    path: "/Navigation200",
    name: "Navigation200",
    component: Navigation200,
  },
  {
    path: "/Navigation300",
    name: "Navigation300",
    component: Navigation300,
  },
  {
    path: "/Navigation500",
    name: "Navigation500",
    component: Navigation500,
  },
  {
    path: "/Navigation600",
    name: "Navigation600",
    component: Navigation600,
  },
  {
    path: "/life",
    component: () => import(/* webpackChunkName: "life" */ "@/layout/default.vue"),
    children: [
      {
        path: "",
        name: "LifeIndex",
        component: () => import(/* webpackChunkName: "LifeIndex" */ "@/views/life/index.vue"),
      },
      {
        path: "student_view",
        name: "StudentView",
        component: () => import(/* webpackChunkName: "StudentView" */ "@/views/life/student_view.vue"),
      },
    ],
  },
  {
    path: "/member",
    component: () => import(/* webpackChunkName: "member" */ "@/layout/default.vue"),
    children: [
      {
        path: "",
        name: "MemberIndex",
        component: () => import(/* webpackChunkName: "MemberIndex" */ "@/views/member/index.vue"),
      },
    ],
  },

  {
    path: "/student",
    component: () => import(/* webpackChunkName: "student" */ "@/layout/default.vue"),
    children: [
      {
        path: "",
        name: "StudentList",
        component: () => import(/* webpackChunkName: "StudentList" */ "@/views/student/index.vue"),
      },
      {
        path: "student_update",
        name: "StudentUpdate",
        component: () => import(/* webpackChunkName: "StudentUpdate" */ "@/views/student/student_update.vue"),
      },
    ],
  },

  {
    path: "/schedule",
    component: () => import(/* webpackChunkName: "schedule" */ "@/layout/default.vue"),
    children: [
      {
        path: "",
        name: "ScheduleIndex",
        component: () => import(/* webpackChunkName: "ScheduleIndex" */ "@/views/schedule/index.vue"),
      },
    ],
  },

  {
    path: "/pay",
    component: () => import(/* webpackChunkName: "pay" */ "@/layout/default.vue"),
    children: [
      {
        path: "",
        name: "PayIndex",
        component: () => import(/* webpackChunkName: "PayIndex" */ "@/views/pay/index.vue"),
      },
    ],
  },


  {
    path: "/community",
    component: () => import(/* webpackChunkName: "community" */ "@/layout/default.vue"),
    children: [
      {
        path: "",
        name: "CommunityIndex",
        component: () => import(/* webpackChunkName: "CommunityIndex" */ "@/views/community/index.vue"),
      },
      {
        path: "faq",
        name: "CommunityFAQ",
        component: () => import(/* webpackChunkName: "CommunityFAQ" */ "@/views/community/faq.vue"),
      },
      {
        path: "inquiry",
        name: "CommunityInquiry",
        component: () => import(/* webpackChunkName: "CommunityInquiry" */ "@/views/community/inquiry.vue"),
      },
    ],
  },

  {
    path: "/setting",
    component: () => import(/* webpackChunkName: "setting" */ "@/layout/default.vue"),
    children: [
      {
        path: "",
        name: "계정관리",
        component: () => import(/* webpackChunkName: "authm" */ "@/views/setting/index.vue"),
      },
      {
        path: "notice",
        name: "SettingNotice",
        component: () => import(/* webpackChunkName: "SettingNotice" */ "@/views/setting/notice.vue"),
      },
      {
        path: "passward_update",
        name: "SettingPasswardUpdate",
        component: () => import(/* webpackChunkName: "SettingPasswardUpdate" */ "@/views/setting/passward_update.vue"),
      },
      {
        path: "email_update",
        name: "SettingEmailUpdate",
        component: () => import(/* webpackChunkName: "SettingEmailUpdate" */ "@/views/setting/email_update.vue"),
      },
    ],
  },

  {
    path: "/diagnosis",
    component: () => import(/* webpackChunkName: "diagnosis" */ "@/layout/default.vue"),
    children: [
      {
        path: "",
        name: "DiagnosisIndex",
        component: () => import(/* webpackChunkName: "DiagnosisIndex" */ "@/views/diagnosis/index.vue"),
      },
    ],
  },

  {
    path: "/DiseaseManagement",
    component: () => import(/* webpackChunkName: "DiseaseManagement_p" */ "@/layout/default.vue"),
    children: [
      {
        path: "",
        name: "DiseaseManagement",
        component: () => import(/* webpackChunkName: "DiseaseManagement" */ "@/views/DiseaseManagement/index.vue"),
      },
    ],
  },

  {
    path: "/symptom",
    component: () => import(/* webpackChunkName: "symptom_p" */ "@/layout/default.vue"),
    children: [
      {
        path: "",
        name: "symptom",
        component: () => import(/* webpackChunkName: "symptom" */ "@/views/symptom/index.vue"),
      },
    ],
  },

  {
    path: "/grade",
    component: () => import(/* webpackChunkName: "grade_p" */ "@/layout/default.vue"),
    children: [
      {
        path: "/grade",
        name: "GradeIndex",
        component: () => import(/* webpackChunkName: "GradeIndex" */ "@/views/service/index.vue"),
      },
      {
        path: "/service",
        name: "ServiceIndex",
        component: () => import(/* webpackChunkName: "ServiceIndex" */ "@/views/service/service.vue"),
      }
    ],
  },

  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },

  {
    path: '/:pathMatch(.*)*',
//        redirect: '/',
    component: () => import(/* webpackChunkName: "default" */ "@/layout/default.vue"),
    children: [
      {
        path: "",
        name: "Index",
        component: () => import(/* webpackChunkName: "Index_p" */ "@/views/index.vue"),
      },
    ],
  }, 
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = Vue.$cookies.get("accessToken");
  const userType = Vue.$cookies.get("user_type");

  if (to.name !== 'Login' && !isAuthenticated && to.name !== 'Register') {
    alert('로그인 후 이용해주세요.');
    next({ name: 'Login' });
  } else if (to.name == 'MasterIndex' && (!isAuthenticated || userType !== "master")) {
    alert('해당 페이지 접근권한이 없습니다.');
    next({ name: 'Login' });
  } else {
    next();
  }
});

export default router;
